<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card style="border-radius: 0;">
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="onClose"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ $t('booking.scheduleForm.title', locale) }}</v-toolbar-title>
          <v-spacer/>
          <v-toolbar-items>
            <delete-item
              v-if="!isNew"
              :id="editedScheduleID"
              :onDeleteItem="handleDelete"
            />
            <v-btn
              dark
              text
              @click="handleSave"
            >
             {{ $t('common.save', locale) }}
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <div
          v-if="editedItem"
          class="scheduleMain"
        >
          <v-layout
            wrap
          >
            <v-flex xs8 style="padding: 15px;">
              <v-text-field
                :label="$t('booking.scheduleForm.alias', locale)"
                v-model="editedItem.Alias"
                outlined
                hide-details
              />
            </v-flex>
            <!--v-flex xs4 style="padding: 15px;">
              <v-select
                v-model="editedItem.Type"
                :items="types"
                :label="$t('booking.scheduleForm.type', locale)"
                itemValue="ID"
                itemText="Name"
                outlined
              />
            </v-flex-->
            <v-flex xs2 style="padding: 15px;" v-if="activitySeats">
              <v-text-field
                outlined
                v-model="editedItem.Seats"
                :label="$t('booking.activities.seats', locale)"
                hide-details
                type="number"
              />
            </v-flex>
            <v-flex xs2 style="padding: 15px;" v-if="activitySeats">
              <v-text-field
                outlined
                v-model="editedItem.SeatsByBooking"
                :label="$t('booking.activities.seatsByBooking', locale)"
                hide-details
                type="number"
              />
            </v-flex>
          </v-layout>
          <v-layout
            wrap
          >
            <v-flex xs4
              v-if="editedItem.Type === 'byDay'"
              style="padding: 0 15px;"
            >
              <center>
                <v-date-picker
                  v-model="editedItem.Dates"
                  :min="minDate"
                  multiple
                  :first-day-of-week="0"
                  :locale="locale"
                  style="width:100%"
                />
              </center>
            </v-flex>
            <v-flex xs4
              v-else-if="editedItem.Type === 'byWeek' || editedItem.Type === 'byMonth'"
              style="padding: 0 15px;"
            >
              <v-layout wrap>
                <v-flex xs1 style="padding-top: 15px">
                </v-flex>
                <v-flex xs11 style="">
                  <custom-datepicker
                    v-model="editedItem.StartDate"
                    :label="'common.startDate'"
                  />
                </v-flex>
                <v-flex xs1 style="padding-top: 15px">
      <v-checkbox v-model="noEndDate" />
                </v-flex>
                <v-flex v-if="noEndDate" xs11 style="padding-top: 35px;">
                  {{ $t('common.noEndDate', locale) }}
                </v-flex>
                <v-flex xs11 style="padding-top: 15px;"
                    v-else
                >
                  <custom-datepicker
                    v-model="editedItem.EndDate"
                    :label="'common.endDate'"
                  />
                </v-flex>
                <v-flex xs12
                  v-if="editedItem.Type === 'byWeek'"
                  style="padding-top: 20px"
                >
                  <v-text-field
                    :label="$t('common.alternation', locale)"
                    v-model="editedItem.Alternation"
                    outlined
                    hide-details
                    type="number"
                    min="0"
                    step="1"
                  />
                </v-flex>
                <v-flex xs12 style="padding-top: 15px;"
                  v-if="editedItem.Type === 'byMonth'"
                >
                <v-btn-toggle v-model="editedItem.Months" multiple>
                  <v-layout wrap>
                    <v-flex xs4
                      v-for="index of 12"
                      :key="`month${index}`"
                      style="padding: 5px;"
                    >
                      <v-btn color="primary" block>{{ $t(`common.m${index-1}`, locale) }}</v-btn>
                    </v-flex>
                  </v-layout>
                </v-btn-toggle>
                </v-flex>
              </v-layout>
            </v-flex>
            <v-flex xs8 style="padding: 0 15px;">
              <v-layout wrap>
                <v-flex xs6 style="padding: 0 15px;">
                  <v-checkbox
                    v-model="editedItem.AllDay"
                    :label="`${$t('booking.scheduleForm.allDay')}`"
                  />
                </v-flex>
                <v-flex xs6 style="padding: 0 15px;">
                  <interval-times
                    :label="`${$t('booking.scheduleForm.duration')}:`"
                    v-model="editedItem.Duration"
                  />
                </v-flex>
                <v-flex xs6 style="padding: 0 15px;">
                  <interval-times
                    :label="`${$t('booking.scheduleForm.startTime')}:`"
                    v-model="editedItem.StartTime"
                  />
                </v-flex>
                <v-flex xs6 style="padding: 0 15px;">
                  <interval-times
                    :label="`${$t('booking.scheduleForm.interval')}:`"
                    v-model="editedItem.Interval"
                  />
                </v-flex>
                <v-flex xs12 style="padding: 0 15px;">
                  <time-line
                    :editedItem="editedItem"
                    :onChange="handleChangeTimetable"
                  />
                </v-flex>
              </v-layout>
            </v-flex>
          </v-layout>
        </div>
      </v-card>
      <custom-dialog
        :showDialog="showError"
        title="error"
        message="error"
        :onOK="() => {showError = false}"
        :onKO="() => {showError = false}"
      />
    </v-dialog>
  </v-row>
</template>
<script>
const types = ['byDay', 'byWeek', 'byMonth']
const defaultSchedule = {
  Alias: null,
  Type: 'byWeek',
  Dates: [],
  StartDate: utils.checkDate(new Date()),
  EndDate: null,//utils.checkDate(new Date()),
  Alternation: 1,
  StartTime: 480,
  Interval: 30,
  AllDay: false,
  Duration: 30,
  Months: [],
  Timetable: {
    0: [],
    1: [],
    2: [],
    3: [],
    4: [],
    5: [],
    6: []
  },
}
import utils from '@/services/utils'
import api from '@/services/api'
import utilsBooking from '@/services/bookings'
import CustomDialog from '@/components/CustomDialog'
import CustomDatepicker from '@/components/CustomDatepicker'
import IntervalTimes from '@/components/intervalTimes/Index'
import DeleteItem from '@/components/DeleteItem'
import TimeLine from '@/components/activityScheduleForm/TimeLine'
import { mapState } from 'vuex'
export default {
  components: {
    CustomDatepicker,
    IntervalTimes,
    TimeLine,
    DeleteItem,
    CustomDialog,
  },
  props: {
    activityID: {
      type: String,
      required: true,
    },
    editedScheduleID: {
      type: String,
      default: null,
    },
    activitySeats: {
      type: Number,
      default: null,
    },
    onClose: {
      type: Function,
      required: true,
    },
  },
  data: () => ({
    dialog: false,
    editedItem: null,
    minDate: utils.checkDate(new Date()),
    types: [],
    showError: false,
    noEndDate: true,
  }),
  computed: {
    ...mapState('app',['locale', 'bookingWorkspace']),
    isNew () {
      return this.editedScheduleID === 'new'
    },
  },
  watch: {
    locale () {
      this.prepareTypes()
    },
    editedScheduleID (v) {
      if (v) {
        this.getData()
        this.dialog = true
      } else this.dialog = false
    },
    activitySeats (v) {
      this.prepareSeats(v)
    },
    noEndDate (v) {
      if (v) this.editedItem.EndDate = null
      else if (!this.editedItem.EndDate) this.editedItem.EndDate = utils.checkDate(new Date())
    }
  },
  mounted() {
    this.prepareTypes()
    if (this.editedScheduleID) this.getData()
  },
  methods: {
    prepareSeats () {
      if (this.activitySeats >= 0 && !this.editedItem.Seats) this.editedItem.Seats = this.activitySeats
      else if(this.activitySeats === null) this.editedItem.Seats = null
    },
    prepareTypes () {
      this.types = types.map(item => ({
        ID: item,
        Name: this.$t(`booking.scheduleForm.${item}`, this.locale),
      }))
    },
    getData () {
      if (this.isNew) {
        this.editedItem = JSON.parse(JSON.stringify(defaultSchedule))
        this.editedItem.ActivityID = this.activityID
        this.editedItem.EndDate = null
        this.prepareSeats()
        this.noEndDate = true
      } else api.getItem('booking', `v1/private/schedules/`, this.editedScheduleID).then(response => {
        this.noEndDate = !response.EndDate
        response.StartDate = ['byWeek', 'byMonth'].indexOf(response.Type) >= 0 ? utils.checkDate(response.StartDate) : utils.checkDate(new Date())
        response.EndDate = ['byWeek', 'byMonth'].indexOf(response.Type) >= 0 && response.EndDate ? utils.checkDate(response.EndDate) : null
        response.Dates = response.Type === 'byDay' ? JSON.parse(response.Dates) : []
        response.Timetable = utils.checkJSON(response.Timetable)
        response.Months = response.Type === 'byMonth' && response.Months ? JSON.parse(response.Months) : []
        this.editedItem = JSON.parse(JSON.stringify(response))

        this.prepareSeats()
      })
    },
    handleCheckAlternation () {
    },
    handleChangeTimetable (v) {
      this.editedItem.Timetable = v
    },
    handleSave () {
      if (this.isNew)
        api.addItem ('booking', 'v1/private/schedules/', this.editedItem)
          .then((response) => {
            this.onClose('add', response.data)
          })
      else 
        api.updateItem ('booking', 'v1/private/schedules/', this.editedScheduleID, this.editedItem)
          .then((response) => {
            const { data } = response
            if (data.info) {
              utilsBooking.handleCheckRelocateReservations('booking', this.bookingWorkspace)
              alert (data.info)
            }
            const id = data && data.ID ? data.ID : null
            this.onClose('update', id)
          })
    },
    handleDelete () {
      api.deleteItem('booking', `v1/private/schedules/`, this.editedScheduleID)
        .then(response => {
          const { data } = response
          if (data.status) this.showError = true
          else if (data.info) {
            utilsBooking.handleCheckRelocateReservations('booking', this.bookingWorkspace)
            alert(data.info)
            this.onClose('delete', this.editedScheduleID)
          } else if(data.affectedRows > 0) this.onClose('delete', this.editedScheduleID)
        })
    },
  },
}
</script>
